<template>
  <!--  <CustomKeypad></CustomKeypad>-->
  <Pending v-if="pending" />
  <div
    v-else
    class="row-input"
    @swipedLeft="onSubmit('next')"
    @swipedRight="onSubmit('prev')"
  >
    <div class="header display-4 flex align-items-center">
      <span>{{ article.name }}</span>
      <span class="text-primary ml-1">{{ article.capacity * 100 }}cl</span>
      <Button
        class="p-button-text p-button-secondary p-button-rounded ml-auto"
        icon="pi pi-times"
        @click="$emit('close')"
      ></Button>
    </div>
    <div class="content">
      <form @submit.prevent="onSubmit('next')" id="form">
        <div class="flex justify-content-between align-items-baseline pb-2">
          <label class="text-sm">Unité</label>
          <div class="hr" />
          <InputText
            type="number"
            step="0.1"
            mode="decimal"
            v-model="row.unit"
            v-focus
          />
        </div>
        <div class="flex justify-content-between align-items-baseline pb-2">
          <label class="text-sm">Conditionné</label>
          <div class="hr" />
          <InputText type="number" mode="decimal" step="1" v-model="row.cond" />
        </div>
        <div class="flex justify-content-between align-items-baseline pb-2">
          <label class="font-italic text-primary">Total</label>
          <InputText
            type="number"
            class="ml-auto input-total"
            disabled
            v-model="total"
          />
        </div>
      </form>
    </div>
    <div class="footer p-fluid flex">
      <Button
        label="Préc."
        class="p-button-text p-button-secondary"
        @click="onSubmit('prev')"
        :disabled="isFirst"
      />
      <Button
        type="submit"
        form="form"
        :label="isLast ? 'Terminé' : 'Suiv.'"
        class="p-button-text"
        :class="isLast ? 'p-button-primary' : 'p-button-success'"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import useDatabase from "../../composables/useDatabase";
import { useRoute } from "vue-router";

export default {
  name: "RowInput",
  props: ["data", "refs", "isLast", "isFirst"],
  setup(props, { emit }) {
    const route = useRoute();
    const { centerId } = route.params;
    const { getOne, updateOne } = useDatabase(
      `centers/${centerId}/rows/${props.data.articleId}`
    );
    const { doc: row, error, pending } = getOne(props.data.id);

    const total = computed(() => {
      if (row.value.unit && row.value.cond) {
        return (
          Number(row.value.unit) + Number(row.value.cond) * article.value.cond
        );
      } else if (!row.value.cond) {
        return row.value.unit;
      } else if (!row.value.unit) {
        return row.value.cond * article.value.cond;
      } else {
        return 0;
      }
    });

    const article = computed(() => {
      return props.refs[props.data.articleId];
    });

    const onSubmit = async (dir) => {
      await updateOne(props.data.id, {
        unit: row.value.unit ? Number(row.value.unit) : null,
        cond: row.value.cond ? Number(row.value.cond) : null,
        total: Number(total.value),
      });
      emit(dir);
    };

    return { article, row, error, pending, total, onSubmit };
  },
};
</script>

<style lang="scss" scoped>
.hr {
  margin: 0 0.3rem;
  border-bottom: dashed 1px rgba($secondary-light, 0.3);
  flex-grow: 1;
}
.row-input {
  background-color: white;
  position: absolute;
  top: 10vh;
  width: calc(100vw - 2rem);
  border-radius: 0.2rem;
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  input {
    max-width: 64px;
    &.input-total {
      border-color: $primary;
      color: $primary;
      font-weight: 500;
    }
  }
  > div {
    padding: 1rem;
    border-bottom: solid 1px #f0f0f0;
    &:last-child {
      border-bottom: none;
    }
  }
  .header {
    grid-area: header;
    padding: 1.25rem;
  }
  .content {
    grid-area: content;
    padding: 1rem;
  }
  .footer {
    grid-area: footer;
    padding: 0.5rem;
  }
}
</style>
