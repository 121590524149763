<template>
  <transition name="fade" appear>
    <div class="row-wrapper" v-if="modelValue >= 0">
      <template v-for="(data, index) in rows" :key="data.id">
        <transition
          mode="in-out"
          @beforeEnter="beforeEnter"
          @enter="enter"
          @beforeLeave="beforeLeave"
        >
          <component
            :is="'RowInput'"
            v-if="index === modelValue"
            @next="navigate('next')"
            @prev="navigate('prev')"
            @close="$emit('update:modelValue', -1)"
            :data="data"
            :refs="refs"
            :isFirst="index === 0"
            :isLast="index === rows.length - 1"
          >
          </component>
        </transition>
      </template>
    </div>
  </transition>
</template>

<script>
import gsap, { Power2 } from "gsap";
import { ref } from "vue";

import RowInput from "./_RowInput";

export default {
  name: "RowInputWrapper",
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    rows: {
      type: Array,
      required: true,
    },
    refs: {
      type: [Array, Object],
      default: () => [],
    },
  },
  emit: ["update:modelValue"],
  components: { RowInput },
  setup(props, { emit }) {
    const direction = ref(null);
    const activeIndex = ref(null);

    const navigate = (dir) => {
      direction.value = dir;
      activeIndex.value = props.modelValue;
      switch (direction.value) {
        case "next":
          if (activeIndex.value !== props.rows.length - 1) {
            activeIndex.value++;
          } else {
            activeIndex.value = -1;
          }
          break;
        case "prev":
          if (activeIndex.value !== 0) {
            activeIndex.value--;
          } else {
            activeIndex.value = -1;
          }
          break;
      }
      emit("update:modelValue", activeIndex.value);
    };

    const beforeEnter = (el) => {
      el.style.transform = `translateX(${
        direction.value === "next" ? "100%" : "-100%"
      })`;
      el.style.opacity = 0;
    };
    const enter = (el, done) => {
      gsap.to(el, {
        duration: 0.5,
        translateX: 0,
        opacity: 1,
        ease: Power2.easeOut,
        onComplete: done,
      });
    };
    const beforeLeave = (el, done) => {
      gsap.to(el, {
        translateX: direction.value === "next" ? "-100%" : "100%",
        opacity: 0,
        ease: Power2.easeOut,
        onComplete: done,
      });
    };

    return { navigate, beforeEnter, enter, beforeLeave, activeIndex };
  },
};
</script>

<style lang="scss" scoped>
.row-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.7);
  backdrop-filter: blur(4px);
}
</style>
