<template>
  <Pending v-if="pending" />
  <template v-else>
    <RowInputWrapper :rows="articles" v-model="rowIndex" :refs="articlesRef" />
    <BaseNotFound
      v-if="!articles.length"
      @action="$emit('addProducts')"
      message="Aucun produit trouvé"
      detail="Ajouter des produits"
    />
    <Draggable
      v-else
      :list="articles"
      group="rows"
      @start="drag = true"
      @end="drag - false"
      item-key="id"
      :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      v-bind="dragOptions"
      @change="onChange"
    >
      <template #item="{ element, index }">
        <div
          class="row-item"
          :class="!$route.query.board ? 'three-cols' : 'four-cols'"
          @click="onRowClick(element, index)"
        >
          <Button
            icon="fa fa-arrows-alt"
            class="handle p-button-text p-button-rounded p-button-secondary"
          />
          <div class="row-name">
            <span>{{ articlesRef[element.articleId]?.name }}</span>
            <span class="pl-1 text-primary"
              >{{ articlesRef[element.articleId]?.capacity * 100 }}cl</span
            >
          </div>
          <InputText
            type="number"
            v-board
            disabled
            v-if="
              rowsRef &&
              rowsRef[element.articleId] &&
              rowsRef[element.articleId][element.id]
            "
            :value="rowsRef[element.articleId][element.id].total"
          />
          <Button
            icon="fa fa-minus"
            class="p-button-text p-button-danger"
            @click.stop="onRemove(element)"
          />
        </div>
      </template>
    </Draggable>
  </template>
</template>

<script>
import { useRoute } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import Draggable from "vuedraggable";
import { ref, computed, defineComponent, watchEffect } from "vue";
import { projectDatabase } from "../../firebase/config";
import { useConfirm } from "primevue/useconfirm";
import useMercurial from "../../composables/useMercurial";

import RowInputWrapper from "./_RowInputWrapper";

export default defineComponent({
  components: { Draggable, RowInputWrapper },
  props: ["areaId"],
  emits: ["updateSelectedArea", "addProducts"],
  setup(props, { emit }) {
    const articlesArray = ref([]);
    const route = useRoute();
    const { mercurialFolder } = useMercurial();
    const { centerId, zoneId } = route.params;
    const confirm = useConfirm();

    const { getOne } = useDatabase(`centers/${centerId}/zones/${zoneId}/areas`);
    const { doc: area, pending: areaPending } = getOne(props.areaId);
    const { docs: articlesRef, pending: articlesRefPending } = useDatabase(
      `${mercurialFolder.value}/articles`
    ).mapAll();
    const { docs: rowsRef, pending: rowsPending } = useDatabase(
      `centers/${centerId}/rows`
    ).mapAll();

    const deleteArticleFromId = async (id) => {
      const updates = {};
      updates[
        `centers/${centerId}/zones/${zoneId}/areas/${props.areaId}/articles/${id}`
      ] = null;
      await projectDatabase.ref().update(updates);
      emit("updateSelectedArea", props.areaId);
    };

    const pending = computed(() => {
      return !!(
        areaPending.value ||
        articlesRefPending.value ||
        rowsPending.value
      );
    });

    watchEffect(async () => {
      articlesArray.value = [];
      if (pending.value || !area.value.articles) return;

      Object.entries(area.value.articles).forEach(async (article) => {
        if (Object.keys(articlesRef.value).includes(article[1].articleId)) {
          articlesArray.value.push({ id: article[0], ...article[1] });
        } else {
          await deleteArticleFromId(article[0]);
        }
      });
    });

    const articles = computed(() => {
      return [...articlesArray.value].sort((a, b) => a.position - b.position);
    });

    const drag = ref(false);
    const dragOptions = ref({
      animation: 150,
      handle: ".handle",
      dragoverBubble: true,
    });

    const onChange = async () => {
      let updates = {};
      articles.value.forEach((article, key) => {
        updates[
          `centers/${centerId}/zones/${zoneId}/areas/${props.areaId}/articles/${article.id}/position`
        ] = key;
        emit("updateSelectedArea", props.areaId);
      });
      await projectDatabase.ref().update(updates);
    };

    const onRemove = (el) => {
      confirm.require({
        header: `Retirer ${articlesRef.value[el.articleId]?.name}`,
        message: "Voulez-vous retirer ce produit du rangement ?",
        acceptClass: "p-button-danger",
        acceptLabel: "Retirer",
        rejectClass: "p-button-secondary p-button-text",
        rejectLabel: "Retour",
        accept: async () => deleteArticleFromId(el.id),
      });
    };

    const onRowClick = (element, index) => {
      if (route.query.board) {
        rowIndex.value = index;
      }
    };

    const rowIndex = ref(-1);

    return {
      articles,
      articlesRef,
      pending,
      drag,
      dragOptions,
      onChange,
      onRemove,
      onRowClick,
      rowIndex,
      rowsRef,
    };
  },
});
</script>

<style lang="scss" scoped>
.row-item {
  padding: 0.2rem 0.75rem;
  font-size: 0.85rem;
  //display: grid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px #f0f0f0;
  cursor: pointer;
  .row-name {
    flex-grow: 1;
  }
  input {
    text-align: center;
    max-width: 48px;
    padding: 0.4rem;
  }
  //&.four-cols {
  //  grid-template-columns: 32px 1fr 48px 32px;
  //}
  //&.three-cols {
  //  grid-template-columns: 32px 1fr 32px;
  //}
  &:last-child {
    border-bottom: none;
  }
  &.sortable-chosen {
    background-color: $primary;
    color: white;
    .p-button-danger {
      opacity: 0;
    }
  }
}
</style>
